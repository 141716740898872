import React, { useEffect } from "react";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadContentPageAction } from "../../../redux/contentPagesSlice";
import { selectEditableContentPage } from "../../../redux/modalSlice";
import { contentPageService } from "../../../services/contentPageService";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import ModalHeader from "../../common/modal/modalHeader/ModalHeader";
import SubmitButton from "../../common/submitButton";
import InputField from "../../form/inputField";
import { InputFieldType } from "../../form/inputField/InputField";
import Textarea from "../../form/textArea";
import styles from "./editContentSectionModal.module.scss";
import { Editor } from '@tinymce/tinymce-react';

interface Props {
	onClose: () => void;
}

function EditContentSectionModal(props: Props) {
	let [title, setTitle] = React.useState("");
	let [content, setContent] = React.useState("");
	let [titleValidated, setTitleValidated] = React.useState(false);
	let [contentValidated, setContentValidated] = React.useState(true);

	let [errorMessage, setErrorMessage] = React.useState("");
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	let dispatch = useDispatch();

	const contentPage = useSelector(selectEditableContentPage);

	useEffect(() => {
		if (!!contentPage?.section?.title && !title)
			setTitle(contentPage!.section!.title);

		if (!!contentPage?.section?.content && !content)
			setContent(contentPage!.section!.content);
	}, [contentPage?.section]);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");

		contentPageService
			.putContentPageSection(contentPage!.cp.key, contentPage!.section.key, title, content)
			.then(() => {
				dispatch(loadContentPageAction(contentPage!.cp.key));
				props.onClose();
			})
			.catch((error) => {
				setErrorMessage("Noget gik galt i forsøget på at opdatere indholdet. Prøv evt igen.");
				setIsSubmitting(false);
			})
	};

	let isValidated = () => titleValidated && contentValidated;

	return (
		<>
			<ModalHeader title={"Ændr tekst"} />
			<form onSubmit={handleFormSubmit}>
				<div className={styles.input}>
					<InputField
						label="Titel"
						name="title"
						placeholder="Kitesurfing..."
						value={title}
						minLength={0}
						type={InputFieldType.text}
						onChangeHandler={(event) =>
							setTitle(event.target.value)
						}
						setIsValid={(isValid) => setTitleValidated(isValid)}
					/>
				</div>

				<div className={styles.textarea}>
					<Editor
						value={content}
						onEditorChange={(event) =>
							setContent(event)
						}
						init={{
							height: 400,
							menubar: false,
							plugins: [
								'lists link preview',
								'searchreplace',
								'insertdatetime paste help wordcount',
								'image'
							],
							toolbar: 'undo redo | formatselect | ' +
								'bold italic | bullist numlist | ' +
								'removeformat | image link',
							content_style: 'body { font-family:"libre-franklin",sans-serif; font-size:16px }'
						}}
					/>
				</div>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title="Send"
					/>
					{!!errorMessage && (
						<StatusMessage
							text={errorMessage}
							status={StatusEnum.error}
						></StatusMessage>
					)}
				</div>
			</form>
		</>
	);
}

export default EditContentSectionModal;
