import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { routes } from "../../../config";
import { selectIsAuthorized } from "../../../redux/authSlice";
import {
	selectMobileMenuIsOpen,
	toggleMenu,
} from "../../../redux/mobileMenuSlice";
import styles from "./mobileMenu.module.scss";
import MobileMenuAccordion from "./mobileMenuAccordion/MobileMenuAccordion";

interface Props {
	isOpen: boolean;
	isAuthorized: boolean;
}

function MobileMenu(props: Props) {
	// ! This also exists in MobileMenuAccordion.tsx
	// ! and should probably only be in one place.
	const dispatch = useDispatch();
	const mobileMenuIsOpen = useSelector(selectMobileMenuIsOpen);

	const onMenuClick = () => {
		dispatch(toggleMenu({ isOpen: !mobileMenuIsOpen }));

		!mobileMenuIsOpen
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "visible");

		// TODO: Close all accordions
	};

	return (
		<div
			className={`${styles.mobileMenu} ${props.isOpen && styles.isOpen}`}
		>
			<nav className={styles.nav}>
				<ul className={styles.ul}>
					<li className={styles.li}>
						<NavLink
							onClick={onMenuClick}
							className={styles.link}
							activeClassName={styles.linkActive}
							to={routes.forside}
							exact
						>
							Hjem
						</NavLink>
					</li>
					<li className={styles.li}>
						<MobileMenuAccordion
							title={"Undervisning"}
							items={[
								{
									route: routes.kiteIntro,
									title: "Kite-intro",
								},
								{
									route: routes.kitesurfing,
									title: "Foil-kitesurfing",
								},
								{
									route: routes.windsurfing,
									title: "Windsurfing",
								},
								{
									route: routes.garnfiskeri,
									title: "Garnfiskeri",
								},
								{
									route: routes.selvforsvar,
									title: "Selvforsvar",
								},
							]}
						></MobileMenuAccordion>
					</li>
					<li className={styles.li}>
						<MobileMenuAccordion
							title={"Udlejning"}
							items={[
								{
									route: routes.barrelSauna,
									title: "Sauna tønde",
								},
								{
									route: routes.vildmarksbad,
									title: "Vildmarksbad",
								},
								{ route: routes.kajak, title: "Kajak" },
								{ route: routes.kano, title: "Kano" },
								{ route: routes.jolle, title: "Jolle" },
								{ route: routes.paddle, title: "Paddle/SUP" },
								{ route: routes.trailer, title: "Trailer" },
								{
									route: routes.baadtrailer,
									title: "Bådtrailer",
								},
								{ route: routes.sikkerhed, title: "Sikkerhed" },
							]}
						></MobileMenuAccordion>
					</li>
					<li className={styles.li}>
						<NavLink
							onClick={onMenuClick}
							className={styles.link}
							activeClassName={styles.linkActive}
							to={routes.raadgivning}
						>
							Rådgivning
						</NavLink>
					</li>
					<li className={styles.li}>
						<MobileMenuAccordion
							title={"Foredrag"}
							items={[
								{
									route: routes.foredrag,
									title: "Foredrag",
								},
								{
									route: routes.absurd,
									title: "Absurd",
								},
							]}
						></MobileMenuAccordion>
					</li>
					<li className={styles.li}>
						<NavLink
							onClick={onMenuClick}
							className={styles.link}
							activeClassName={styles.linkActive}
							to={routes.omOs}
						>
							Om os
						</NavLink>
					</li>

					{!!props.isAuthorized && (
						<li className={styles.li}>
							<NavLink
								onClick={onMenuClick}
								className={styles.link}
								activeClassName={styles.linkActive}
								to={routes.adminMain}
							>
								Admin
							</NavLink>
						</li>
					)}
				</ul>
			</nav>
			<footer className={styles.footer}>
				<a className={styles.footerInfo} href="tel:23244171">
					<span className={`material-icons ${styles.footerInfoIcon}`}>
						phone
					</span>
					23 24 41 71
				</a>
				<a
					className={styles.footerInfo}
					href="mailto:mail@aarhusvandsportscenter.dk"
				>
					<span className={`material-icons ${styles.footerInfoIcon}`}>
						email
					</span>
					mail@aarhusvandsportscenter.dk
				</a>
			</footer>
		</div>
	);
}

export default MobileMenu;
