import { useSelector } from "react-redux";
import { Spinner } from "../../components/common";
import { SpinnerTheme } from "../../components/common/spinner/Spinner";
import EditableContent from "../../components/common/editableStuff/editableContent";
import {
	selectContentPageSection,
	useContentPage,
} from "../../redux/contentPagesSlice";
import styles from "./subpage.module.scss";
import Top from "./top/Top";
import { useRef } from "react";
import { useRentalProducts } from "../../redux/rentalSlice";
import AccordionPrice from "./accordion/AccordionPrice";
import AccordionSection from "./accordion/AccordionSection";

interface Props {
	pageKey: string;
	disableTopSection: boolean;
	disableImg?: boolean;
	smallImg?: boolean;
	productId?: number; // this will be null if this subpage is not a productpage
	secondaryProductId?: number; // this can have a value if two products are displayed on the same page. example: kajak + dobbeltkajak
	title?: string;
}

function Subpage(props: Props) {
	const scrollElement = useRef<HTMLDivElement>(null);

	useContentPage(props.pageKey);
	const rentalProducts = useRentalProducts();
	// this will be null if this subpage is not a productpage
	const thisProduct = rentalProducts.products.find(product => product.id === props.productId)!;
	const thisSecondaryProduct = rentalProducts.products.find(product => product.id === props.secondaryProductId)!;

	let section_intro = useSelector(
		selectContentPageSection(props.pageKey, "intro")
	);

	let section_one = useSelector(
		selectContentPageSection(props.pageKey, "section_1")
	);

	let section_two = useSelector(
		selectContentPageSection(props.pageKey, "section_2")
	);

	let section_three = useSelector(
		selectContentPageSection(props.pageKey, "section_3")
	);

	const accordions = thisProduct?.prices || section_one  || section_two || section_three;

	return (
		<main>
			{!section_intro ? (
				<Spinner theme={SpinnerTheme.Light} />
			) : (
				<div>
					<EditableContent
						pageKey={props.pageKey}
						sectionKey={section_intro.key}
					>
					{!props.disableTopSection && (
						<Top
							heading={!!section_intro && section_intro.title}
							image={props.disableImg ? undefined : `/images/${props.pageKey}.png`}
							imageSize={!!props.smallImg ? "small" : "regular"}
							element={scrollElement}
						></Top>
					)}
						<div className={`${styles.content} ${accordions && styles.grid}`} ref={scrollElement}>
							{!!section_intro && <div dangerouslySetInnerHTML={{__html: section_intro!.content}}></div>}

							<div className={accordions && styles.accordions}>
								{thisProduct?.prices && (
									<AccordionPrice
										title={!!thisSecondaryProduct ? `Pris - ${thisProduct.namePlural}` : "Pris"}
										product={thisProduct}/>
								)}

								{thisSecondaryProduct?.prices && (
									<AccordionPrice title={`Pris - ${thisSecondaryProduct.namePlural}`} product={thisSecondaryProduct}/>
								)}

								{section_one && (
									<EditableContent
										pageKey={props.pageKey}
										sectionKey={section_one.key}
									>
										<AccordionSection title={section_one.title} content={section_one.content} />
									</EditableContent>
								)}

								{section_two && (
									<EditableContent
										pageKey={props.pageKey}
										sectionKey={section_two.key}
									>
										<AccordionSection title={section_two.title} content={section_two.content} />
									</EditableContent>
								)}

								{section_three && (
									<EditableContent
										pageKey={props.pageKey}
										sectionKey={section_three.key}
									>
										<AccordionSection title={section_three.title} content={section_three.content} />
									</EditableContent>
								)}
							</div>
						</div>
					</EditableContent>
				</div>
			)}
		</main>
	);
}

export default Subpage;
